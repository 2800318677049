@import "../helpers/variables";

header {
  padding-top: 40px;
  position: relative;
  z-index: 2;
  .logo {
    display: inline-block;
  }
  @media #{$mobile} {
    padding-top: 20px;
    img {
      height: 45px;
    }
  }
}
