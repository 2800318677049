@import "../helpers/variables";

footer {
  padding-bottom: 40px;
  padding-top: 100px;
  position: sticky;
  top: 100%;
  @media #{$maxTab} {
    padding-top: 50px;
  }
  .logo {
    margin-left: -5px;
  }
  .copyright {
    color: #62626b;
  }
  .footer-menu {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 16px;
    @media #{$maxTab} {
      justify-content: flex-start;
    }
    ul {
      display: flex;
      gap: 16px;
      @media #{$maxTab} {
        flex-direction: column;
        gap: 5px;
        flex: 1;
      }
    }
    a {
      font-weight: 500;
      transition: 0.2s;
      @media (min-width: 1200px) {
        font-size: 18px;
      }

      &:hover {
        color: #8280ed;
      }
    }
  }
  .footer-col {
    @media #{$mobile} {
      margin-bottom: 30px;
    }
  }
}
