@import "sass/global_styles/bootstrap-classes";
@import "sass/global_styles/global";
@import "sass/global_styles/header";
@import "sass/global_styles/footer";

.main {
  position: relative;
  z-index: 1;
  @media (min-width: 1200px) {
    margin-top: -50px;
  }
  @media #{$maxTab} {
    margin-top: 48px;
  }
  .hero-text {
    position: relative;
    z-index: 2;
    @media #{$maxTab} {
      text-align: center;
    }
    h1 {
      color: #0e0e11;
      letter-spacing: -1.76px;
      font-size: 70px;
      @media (min-width: 1400px) {
        font-size: 88px;
        line-height: 98px;
      }
      @media #{$betweentabdesk} {
        font-size: 56px;
        letter-spacing: -1.56px;
        line-height: 110%;
      }
      @media #{$tablet} {
        font-size: 48px;
        letter-spacing: -0.56px;
        line-height: 120%;
      }
      @media #{$mobile} {
        font-size: 28px;
        letter-spacing: -0.56px;
        line-height: normal;
      }
    }
  }
  .app-btns {
    margin-top: 40px;
    display: flex;
    gap: 20px;
    @media (min-width: 992px) and (max-width: 1199px) {
      gap: 12px;
    }
    @media #{$maxTab} {
      margin-top: 16px;
      gap: 9px;
      justify-content: center;
    }
    a {
      transition: 0.2s;
      &:hover {
        opacity: 0.8;
      }
    }
    img {
      @media (min-width: 768px) and (max-width: 1199px) {
        height: 40px;
      }

      @media #{$mobile} {
        height: 25px;
      }
    }
  }

  .hero-img {
    position: relative;
    z-index: 1;
    img {
      transform: translateY(10%);
      @media #{$betweentabdesk} {
        height: 500px;
      }
      @media #{$maxTab} {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .section {
    border-radius: 40px;
    overflow: hidden;
    background-color: #fff;
    margin-top: 20px;
    @media #{$mobile} {
      border-radius: 20px;
    }
    .section-image,
    .section-text {
      display: flex;
      justify-content: center;
    }
    .section-image {
      padding: 50px 50px 0;
      height: 100%;
      align-items: flex-end;
    }
    &.know-calling {
      margin-top: 50px;
      @media #{$maxTab} {
        margin-top: 10px;
      }
      .section-image {
        background-color: #e1e0f9;
      }
    }
    &.block-spammers {
      .section-image {
        background-color: #ffd8d6;
      }
    }
    &.search-numbers {
      .section-image {
        background-color: #ffddad;
      }
    }
    &.callback {
      .section-image {
        background-color: #d5f0ef;
      }
    }
    .section-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0 80px;
      @media #{$tablet} {
        padding: 36px;
      }
      @media #{$mobile} {
        padding: 24px;
      }
      h2 {
        color: #0e0e11;
        font-size: 64px;
        line-height: 64px; /* 100% */
        letter-spacing: -1.28px;
        margin-bottom: 30px;
        @media #{$betweentabdesk} {
          font-size: 42px;
          letter-spacing: -1.56px;
          margin-bottom: 20px;
          line-height: 110%;
        }
        @media #{$maxTab} {
          line-height: normal;
          font-size: 28px;
          letter-spacing: -0.56px;
          margin-bottom: 12px;
        }
      }
      p {
        color: #0e0e11;
        font-size: 22px;
        font-weight: 500;
        line-height: 34px; /* 154.545% */
        letter-spacing: -0.44px;
        padding-right: 70px;

        @media (max-width: 1199px) {
          font-size: 18px;
          letter-spacing: -0.36px;
          line-height: 28px;
          padding-right: 0;
        }
      }
    }
  }
}
