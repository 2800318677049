@import "../helpers/variables";

@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-weight: normal;
  font-style: normal;
  position: relative;
  overflow-x: hidden;
  color: #2d2d32;
  background-color: #f2f2fa;
  font-family: "Tajawal", sans-serif;
  line-height: 160%;
}

a {
  text-decoration: none;
  color: #2d2d32;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 120%;
  color: #0e0e11;
}

p,
li {
  color: #2d2d32;
  line-height: 170%;
}

ul {
  list-style: none;
  padding: 0;
}

button:focus,
input:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

input {
  &::placeholder {
    color: #777c80;
  }
}

input:focus::placeholder,
textarea:focus::placeholder {
  color: rgb(188, 188, 188);
}

input,
textarea,
button {
  font-family: "Tajawal";
}

button {
  cursor: pointer;
  border: 0;
}

.wrapper {
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}

.banner-gradient-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0.2;
  filter: blur(216px);
  z-index: 0;
  @media #{$mobile} {
    filter: blur(62px);
  }
}

.banner-gradient-bg .circle {
  width: 33.33%;
  height: 841px;
  border-radius: 50%;

  position: absolute;
  top: 0;
  @media (max-width: 500px) {
    height: 243px;
    width: 243px;
  }
  @media (min-width: 501px) and(max-width: 800px) {
    height: 500px;
    width: 500px;
  }
}

.banner-gradient-bg .one {
  background: #00c7be;
  left: -6.7%;
  @media #{$mobile} {
    left: -30%;
  }
}

.banner-gradient-bg .two {
  background: #5856d6;
  left: 50%;
  transform: translateX(-50%);
}

.banner-gradient-bg .three {
  background: #af52de;
  right: -6.7%;
  @media #{$mobile} {
    right: -30%;
  }
}
